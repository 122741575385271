<script>
  import LL from "$i18n/i18n-svelte";
  import { Campaign, Groups, NotificationsActive, PinDrop } from "@steeze-ui/material-design-icons";
  import { Icon } from "@steeze-ui/svelte-icon";
</script>

<section class="hidden md:block">
  <div class="items-center text-center py-8 px-4 mx-auto max-w-screen-xl">
    <div class="font-light text-sm text-gray-500 dark:text-gray-400">
      <div class="grid gap-8 items-center grid-cols-1 sm:grid-cols-2 lg:grid-cols-4">
        <div>
          <Icon class="mx-auto h-12 w-12 mb-2 text-emerald-400" src={Groups} />
          <p class="text-base font-semibold text-gray-900 dark:text-white">{$LL.gettingStarted.createGroup()}</p>
          <p>{$LL.gettingStarted.createGroupDesc()}</p>
        </div>
        <div>
          <Icon class="mx-auto h-12 w-12 mb-2 text-emerald-400" src={PinDrop} />
          <p class="text-base font-semibold text-gray-900 dark:text-white">
            {$LL.gettingStarted.upcomingRides()}
          </p>
          <p>{$LL.gettingStarted.upcomingRideDesc()}</p>
        </div>
        <div>
          <Icon class="mx-auto h-12 w-12 mb-2 text-emerald-400" src={Campaign} />
          <p class="text-base font-semibold text-gray-900 dark:text-white">{$LL.gettingStarted.stayConnected()}</p>
          <p>{$LL.gettingStarted.stayConnectedDesc()}</p>
        </div>
        <div>
          <Icon class="mx-auto h-12 w-12 mb-2 text-emerald-400" src={NotificationsActive} />
          <p class="text-base font-semibold text-gray-900 dark:text-white">{$LL.gettingStarted.followFriends()}</p>
          <p>{$LL.gettingStarted.followFriendsDesc()}</p>
        </div>
      </div>
    </div>
  </div>
</section>
