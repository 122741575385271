<script lang="ts">
  import LL from "$i18n/i18n-svelte";
  import authStore from "$lib/stores/authStore";
  import { getLocationStorage } from "$lib/utils/locationSearch";
  import { tsClient } from "$src/lib/utils/typesense";
  import { ChevronLeft, ChevronRight, Add } from "@steeze-ui/material-design-icons";
  import { Icon } from "@steeze-ui/svelte-icon";
  import { onMount } from "svelte";
  import RideItemLoading from "../ui/rideList/RideItemLoading.svelte";
  import RideItemVertical from "../ui/rideList/RideItemVertical.svelte";
  let locData, rideResults;
  let loading = false;
  let local = true;

  async function search() {
    loading = true;
    rideResults = [];
    await queryRides(true);
    if (rideResults.length === 0 && local) {
      local = false;
      await queryRides(false);
    }
    loading = false;
  }

  async function queryRides(local) {
    const client = tsClient();
    const date = new Date();
    const timestamp = Math.floor(date.getTime() / 1000);
    
    let filterBy;
    let sortBy = "date:asc";

    if (locData?.geoCode?.latitude && locData?.geoCode?.longitude && local){
      filterBy = `date:>${timestamp} && location:(${locData.geoCode.latitude}, ${locData.geoCode.longitude}, 200 mi)`;
    } else if (locData?.geoCode?.latitude && locData?.geoCode?.longitude && !local) {
      sortBy = `location(${locData.geoCode.latitude}, ${locData.geoCode.longitude}):asc`;
      filterBy = `date:>${timestamp}`;
    } else{
      filterBy = `date:>${timestamp}`;
    }

    let searchParameters = {
      q: "*",
      query_by: "title, details",
      sort_by: sortBy,
      filter_by: filterBy,
    };

    const results = await client.collections("rides").documents().search(searchParameters);

    if (results?.hits) {
      rideResults.push(...results?.hits);
      rideResults = rideResults;
    }
  }

  function scrollRides(value) {
    document.getElementById("localRides").scrollBy({
      left: value,
      behavior: "smooth",
    });
  }

  onMount(async () => {
    locData = await getLocationStorage();
    if (location) search();
  });
</script>

{#if locData?.location}
  <div class="m-4 text-3xl tracking-tight font-semibold text-gray-900 dark:text-white">
    {#if local}
      {$LL.home.findLocalRides()}
      {locData.location}
    {:else}
    {$LL.home.findUpcomingRides()}
    {/if}
  </div>
  {#if rideResults.length > 2}
    <div class="relative max-w-5xl hidden xl:block">
      <div class="absolute top-60 -left-12 z-10">
        <button
          on:click={() => scrollRides(-500)}
          class=" bg-emerald-400 hover:bg-emerald-500 text-gray-800 p-3 rounded-full transition ease-in duration-300"
        >
          <Icon class="w-4 h-4 stroke-2" src={ChevronLeft} />
        </button>
      </div>
      <div class="absolute top-60 -right-12 z-10">
        <button
          on:click={() => scrollRides(500)}
          class=" bg-emerald-400 hover:bg-emerald-500 text-gray-800 p-3 rounded-full transition ease-in duration-300"
        >
          <Icon class="w-4 h-4 stroke-2" src={ChevronRight} />
        </button>
      </div>
    </div>
  {/if}
  <div
    id="localRides"
    class="flex flex-nowrap gap-3 p-5 overflow-x-auto scroll-smooth snap-x snap-mandatory no-scrollbar"
  >
    {#if loading}
      <RideItemLoading />
      <RideItemLoading />
    {:else if rideResults.length === 0}
      <div class="w-full my-8 flex flex-col justify-center items-center">
        <div class="text-3xl text-left text-gray-500 dark:text-gray-400">
          {$LL.home.noLocalRides()}
          
        </div>
        <a
        href="/create-ride"
        class="py-2.5 px-3 mt-4 text-sm font-medium text-black focus:outline-none bg-white rounded-md border-2 border-emerald-400 hover:bg-gray-100 focus:z-10 dark:bg-gray-800 dark:text-green-400 dark:border-emerald-500 dark:hover:bg-gray-700"
        ><div class="flex items-center gap-2">
          <img class="h-4 block dark:hidden" alt="logo" src="/images/logos/headerLogoMobile.webp" />
          <img class="h-4 hidden dark:block" alt="logo" src="/images/logos/headerLogoWhiteMobile.webp" />
          <div>{$LL.header.listRide()}</div>
        </div></a
      >
      </div>
    {:else}
      {#each rideResults as ride}
        <div class="mb-3 px-2 snap-center snap-always">
          <RideItemVertical ride={ride.document} units={$authStore?.profile?.units || "Imperial"} />
        </div>
      {/each}
    {/if}
    {#if rideResults.length > 0}
      <a
        href="/create-ride"
        class="flex flex-col min-w-[200px] mb-3 px-2 h-auto snap-center snap-always bg-white border-2 rounded-lg shadow-md border-emerald-200 dark:border-emerald-800 dark:bg-gray-800"
      >
        <div class="mx-auto my-auto text-lg font-medium text-black dark:text-emerald-500">
          <img
            class="h-12 mx-auto mb-6  block dark:hidden"
            alt="logo"
            src="/images/logos/headerLogoMobile.webp"
          />
          <img
            class="h-12 mx-auto mb-6 hidden dark:block"
            alt="logo"
            src="/images/logos/headerLogoWhiteMobile.webp"
          />{$LL.header.listRide()}
        </div>
      </a>
    {/if}
  </div>
{/if}
