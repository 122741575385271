<script lang="ts">
  import LL from "$i18n/i18n-svelte";
  import {
    getFeetFromMeters,
    getKilometersFromMeters,
    getMilesFromMeters,
  } from "$src/lib/utils/distanceConvert";
  import paginate from "$src/lib/utils/paginateArray";
  import { Autorenew, Schedule } from "@steeze-ui/material-design-icons";
  import { Icon } from "@steeze-ui/svelte-icon";
  import { Avatar, Popover } from "flowbite-svelte";
  import { truncateString } from "$src/lib/utils/truncateString";
  export let ride;
  export let units;
  let rideDate = new Date(0);
  rideDate.setUTCSeconds(ride.date);
</script>

<div
  class="flex flex-col w-[350px] h-[475px] bg-white border rounded-lg shadow-md dark:border-gray-700 dark:bg-gray-800"
>
  <div class="relative flex w-full">
    <a href={`/rides/${ride.shortId}/details`} class="w-full h-full items-center">
      {#if ride.headerImageUrl}
        <img
          class="object-cover w-full rounded-t-lg h-56 rounded-none rounded-l-lg"
          src={ride.headerImageUrl}
          alt=""
        />
      {:else}
        <div
          class="flex rounded-t-lg rounded-l-lg rounded-none h-56 justify-center items-center bg-gray-200 dark:bg-gray-700"
        >
          <svg
            fill="currentColor"
            class="w-20 h-20 text-gray-400 dark:text-gray-500"
            viewBox="0 0 183.11633 147.33672"
          >
            <path
              d="m 178.18207,106.14534 -32.51172,-21.003903 22.10156,-21.04688 c 14.6211,-13.94531 16.32813,-36.0039 3.75,-49.21094 -12.55468,-13.1640601 -34.67968,-12.5546801 -49.32422,1.39454 l -74.031245,70.55078 c -4.042969,3.83984 -4.203125,10.24218 -0.339844,14.285153 3.84375,4.04297 10.242188,4.19922 14.289063,0.33594 L 136.14691,30.922687 c 6.44532,-6.15625 15.94532,-7.1211 20.75,-2.08985 4.78516,5.03125 3.3711,14.48828 -3.07422,20.61719 l -25.60546,24.41797 c -7.65625,7.29687 -6.48438,19.83984 2.3789,25.61328 0.0156,0.008 0.0234,0.0156 0.0234,0.0156 l 37.01562,23.894533 c 1.6875,1.10156 3.59375,1.62109 5.46094,1.62109 3.19141,0 6.31641,-1.51172 8.27344,-4.27734 3.33984,-4.72657 1.67969,-11.44532 -3.1875,-14.58985"
            />
            <path
              d="m 100.42816,80.270337 c -4.95312,0.33203 -8.69531,4.61719 -8.36328,9.56641 1.28907,19.121093 -12.02343,35.894533 -30.964841,39.023443 -9.769531,1.61328 -19.589844,-0.67188 -27.640625,-6.44532 -8.050782,-5.76953 -13.375,-14.32812 -14.992188,-24.101563 -3.328125,-20.17578 10.375,-39.30078 30.550782,-42.63281 3.921874,-0.64844 7.886718,-0.67188 11.777343,-0.0586 4.925781,0.76953 9.5,-2.58985 10.265625,-7.48829 0.765625,-4.90234 -2.585937,-9.5 -7.488281,-10.26562 -5.78125,-0.90234 -11.664063,-0.875 -17.480469,0.082 C 16.139101,42.899207 -4.2046492,71.285927 0.74066373,101.23905 3.1351948,115.74687 11.037539,128.4539 22.990664,137.0203 c 9.457031,6.77735 20.546875,10.32032 31.953125,10.31641 3.011719,0 6.050781,-0.24609 9.082031,-0.74609 28.12109,-4.64454 47.87891,-29.5625 45.96875,-57.960943 -0.33203,-4.94922 -4.61328,-8.69141 -9.56641,-8.35938"
            />
            <path
              d="m 79.81879,29.430497 c 8.07422,-1.33203 13.53906,-8.95703 12.20312,-17.02734 C 90.68988,4.3289369 83.06488,-1.1320031 74.99457,0.2000269 c -8.074219,1.33203 -13.535156,8.95703 -12.203125,17.0273401 1.332031,8.07422 8.957031,13.53907 17.027345,12.20313"
            />
          </svg>
        </div>
      {/if}
    </a>
    <span
      id="recurring{ride.id}"
      class="absolute bottom-3 left-2 bg-green-400 text-gray-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded-full"
      ><div class="flex">
        {#if ride.recurring}
          <Icon class="w-4 h-4 stroke-2" src={Autorenew} />
        {:else}
          <Icon class="w-4 h-4 stroke-2" src={Schedule} />
        {/if}
        <div class="text-xs ml-1">
          {rideDate.toLocaleTimeString("en-us", {
            month: "short",
            day: "numeric",
            hour: "numeric",
            minute: "2-digit",
          })}
        </div>
      </div></span
    >
    {#if ride.recurring}
      <Popover
        arrow={false}
        class="text-xs z-10 whitespace-nowrap rounded-full font-light shadow-none"
        placement="top"
        triggeredBy="#recurring{ride.id}"
      >
        {$LL.rideDetails.recurringRide()}
      </Popover>
    {/if}
    <a class="absolute top-3 right-2" href={`/profile/${ride.ownerShortId}/rides`}>
      <Avatar
        id="avatar{ride.id}"
        size="md"
        class="border rounded-full border-emerald-400"
        src={ride.ownerPhotoUrl}
      />
    </a>
    <Popover
      arrow={false}
      class="flex justify-center w-44 text-xs rounded-full font-light shadow-none z-10"
      placement="bottom"
      triggeredBy="#avatar{ride.id}"
    >
      <a href={`/profile/${ride.ownerShortId}/rides`}
        >{$LL.rideDetails.listedBy()} {ride.ownerName}</a
      >
    </Popover>
    {#if ride.groupHeaderUrl}
      <a href={`/groups/${ride.groupShortId}/details`}>
        <img
          id="group{ride.id}"
          class="absolute w-10 h-10 top-3 left-2 rounded border object-cover border-emerald-400"
          src={ride.groupHeaderUrl}
          alt="Default avatar"
        />
      </a>
      <Popover
        arrow={false}
        class="text-xs rounded-full font-light shadow-none"
        placement="bottom"
        triggeredBy="#group{ride.id}"
      >
        <a href={`/groups/${ride.groupShortId}/details`}>{ride.groupName}</a>
      </Popover>
    {/if}
    {#if ride.category}
      <span
        class="absolute bottom-3 right-2 bg-green-400 text-gray-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded-full"
        ><div class="flex">
          <div class="text-xs ml-1">{$LL.search.filters.categories[ride.category]()}</div>
        </div></span
      >
    {/if}
  </div>
  <div class="relative p-2 leading-normal w-full h-full">
    <div class="flex items-center justify-between">
      <div>
        <a
          href={`/rides/${ride.shortId}/details`}
          class="mb-2 text-sm font-bold text-gray-900 dark:text-white"
        >
          {ride.title}
        </a>
        <div class="flex pt-1">
          {#if ride.event}
            <span
              class="bg-yellow-100 text-yellow-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-yellow-900 dark:text-yellow-300"
              >Event</span
            >
          {/if}
          {#if ride.type}
            <span
              class="bg-green-100 text-green-800 mr-1 text-xs font-medium px-2 py-0.5 rounded dark:bg-green-900 dark:text-green-300"
              >{$LL.search.filters.types[ride.type]()}</span
            >
          {/if}
        </div>
      </div>
      <div>
        <div class="ml-3 mb-1 flex" id="address{ride.id}">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="2"
            stroke="currentColor"
            class="w-7 h-7 text-red-400 dark:text-red-600"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z"
            />
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z"
            />
          </svg>
        </div>
        <Popover
          arrow={false}
          class="text-xs z-50 w-full rounded-full font-light shadow-none"
          placement="top"
          triggeredBy="#address{ride.id}"
        >
          <a
            href="https://www.google.com/maps/dir/?api=1&dir_action=navigate&destination={ride.address}"
            target="_blank"
            rel="noreferrer">{ride.address}</a
          >
        </Popover>
      </div>
    </div>
    <hr class="h-px m-2 bg-gray-200 border-0 dark:bg-gray-700" />
    <div class="w-full pb-8">
      <p class="p-2 text-sm text-gray-700 dark:text-gray-400">
        {truncateString(ride.summary, 250)}
      </p>
    </div>
    <div class="absolute inset-x-0 bottom-0 p-2">
      <hr class="h-px my-2 bg-gray-200 border-0 dark:bg-gray-700" />
      <div class="flex justify-between h-4">
        <div class="flex items-center w-16 ml-4">
          <div class="flex w-full">
            {#if ride?.memberDetails?.length > 0}
              <div class="flex w-full items-center">
                <a class="flex w-full" href={`/rides/${ride.shortId}/cyclists`}>
                  {#each paginate(ride?.memberDetails, 4, 1) as attendee, i}
                    <Avatar
                      size="xs"
                      src={attendee.photoUrl || ""}
                      stacked={true}
                      border
                      class="border-0 p-0 z-{i * 10} ring-emerald-400 dark:ring-emerald-400"
                    />
                    <Popover class="z-10">{attendee.name}</Popover>
                  {/each}
                  {#if ride?.memberDetails?.length > 4}
                    <Avatar
                      size="xs"
                      stacked
                      href="/"
                      border
                      class="border-0 -ml-3 z-50 bg-gray-700 text-white hover:bg-gray-600 !ring-emerald-400 dark:ring-emerald-400 text-xs"
                      >+{ride?.memberDetails.length - 4}</Avatar
                    >
                  {/if}
                </a>
              </div>
            {/if}
          </div>
        </div>
        <div class="flex items-center gap-2 mr-2">
          {#if ride.effort}
            <div class="flex">
              <svg
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                stroke="currentColor"
                class="w-4 h-4 text-gray-500 dark:text-gray-400"
                viewBox="0 0 1000 1000"
                enable-background="new 0 0 1000 1000"
                xml:space="preserve"
              >
                <g
                  ><g transform="translate(0.000000,511.000000) scale(0.100000,-0.100000)"
                    ><path
                      stroke-width="450"
                      d="M4665.2,4136.7c-37.4-3.9-153.5-17.7-255.9-29.5c-726.3-82.7-1555-389.7-2165.2-805c-362.2-246-799.1-645.6-1055-964.5C651.7,1668.4,311.2,912.5,155.7,44.5c-59.1-334.6-74.8-1078.6-29.5-1427.1c74.8-566.9,250-1149.5,488.1-1621.9c159.4-314.9,519.6-852.3,604.3-895.6c27.5-15.7,1184.9-21.7,3793-21.7h3753.6l74.8,78.7c94.5,98.4,379.9,527.5,498,748c265.7,498,460.6,1122,533.4,1712.5c33.5,283.5,39.4,858.2,7.9,1137.7c-63,582.6-220.4,1118-490.1,1667.2c-250,505.9-515.7,879.9-915.3,1281.4C7718,3461.5,6755.5,3939.8,5665.1,4099.3C5468.2,4128.8,4804.9,4152.4,4665.2,4136.7z M5669,3784.3c498-74.8,923.2-210.6,1377.8-435c490.1-244.1,873.9-525.5,1265.6-933c708.6-734.2,1131.8-1608.1,1263.7-2604.1c39.4-301.2,39.4-866.1,0-1169.2c-98.4-753.9-377.9-1460.5-832.6-2096.3l-108.3-153.5H4999.8H1362.3l-122,175.2c-437,618-722.4,1340.4-816.9,2074.6c-39.4,301.2-39.4,868.1,0,1169.2c196.8,1511.7,1155.4,2854.1,2525.4,3537.1c541.3,269.7,1015.7,401.5,1706.6,472.4C4816.7,3837.5,5470.2,3813.9,5669,3784.3z"
                    /><path
                      stroke-width="450"
                      d="M4883.7,3290.3c-39.4-49.2-41.3-76.8-41.3-472.4c0-230.3,5.9-433,11.8-450.7c7.9-15.7,39.4-45.3,70.8-61c47.2-23.6,70.8-25.6,126-7.9c37.4,11.8,76.7,39.4,86.6,59.1c11.8,21.7,19.7,216.5,19.7,458.6c0,397.6-2,425.2-41.3,474.4c-31.5,39.4-57.1,51.2-116.1,51.2S4915.1,3329.7,4883.7,3290.3z"
                    /><path
                      stroke-width="450"
                      d="M2023.7,2123.1c-61-49.2-84.6-124-53.1-185c11.8-23.6,151.6-173.2,309-332.7c265.7-267.7,295.3-291.3,360.2-291.3c88.6,0,161.4,70.9,161.4,159.4c0,51.2-41.3,102.4-303.1,364.2C2167.4,2168.3,2118.1,2197.9,2023.7,2123.1z"
                    /><path
                      stroke-width="450"
                      d="M7824.3,2140.8c-21.6-7.9-171.2-149.6-332.7-311c-251.9-253.9-293.3-305.1-293.3-356.3c0-88.6,72.8-159.4,161.4-159.4c65,0,94.5,23.6,360.2,291.3c157.5,159.4,297.2,309,309,332.7c29.5,59.1,7.9,135.8-51.2,183.1C7924.7,2162.4,7887.3,2168.3,7824.3,2140.8z"
                    /><path
                      stroke-width="450"
                      d="M7601.9,1150.7c-187-78.7-503.9-313-2062.8-1533.3l-395.6-311h-139.7c-187,0-320.8-29.5-486.2-108.3c-194.9-94.5-354.3-230.3-476.3-407.5C3903.4-1414,3858.1-1563.6,3868-1790c21.7-503.9,332.7-919.2,807-1080.6c389.7-129.9,793.2-29.5,1092.4,269.7c277.5,275.6,417.3,722.4,332.7,1045.2l-23.6,84.6l600.3,750C7605.9,440.1,7883.4,820,7907,965.7c17.7,114.2-66.9,232.3-165.4,230.3C7722,1196,7659,1174.3,7601.9,1150.7z M7220.1,475.6c-35.4-49.2-377.9-482.2-759.8-960.5c-381.9-476.3-702.7-887.7-712.5-909.4c-11.8-29.5-3.9-82.7,23.6-167.3c29.5-96.5,37.4-165.3,31.5-293.3c-17.7-377.9-259.8-663.3-627.9-740.1c-277.5-59-564.9,23.6-757.8,218.5c-124,124-179.1,226.4-218.5,411.4c-61,293.3,17.7,551.1,230.3,746c171.3,159.5,285.4,198.8,570.8,200.8c126,2,240.1,9.8,255.9,19.7c15.7,9.8,387.8,297.2,826.7,639.7c854.3,667.3,1186.9,925.1,1196.7,925.1C7283.1,566.1,7255.5,524.8,7220.1,475.6z"
                    /><path
                      stroke-width="450"
                      d="M1230.4-727.1c-350.4-5.9-375.9-9.8-417.3-49.2c-63-59.1-61-159.4,3.9-222.4c47.3-49.2,47.3-49.2,470.4-49.2c232.3,0,437,5.9,454.7,11.8c37.4,15.7,88.6,108.3,88.6,163.4c0,63-80.7,137.8-157.5,145.6C1635.9-725.1,1437.1-723.2,1230.4-727.1z"
                    /><path
                      stroke-width="450"
                      d="M8292.8-729.1c-59-7.9-124-94.5-124-165.4c0-33.4,19.7-76.7,49.2-104.3l47.2-49.2h435h435l47.2,49.2c65,63,66.9,163.4,3.9,222.4c-41.3,39.4-66.9,41.3-446.8,47.2C8517.2-725.1,8316.4-725.1,8292.8-729.1z"
                    /></g
                  ></g
                >
              </svg>
              <div class="text-xs ml-1 text-gray-900 dark:text-white">
                {$LL.search.filters.efforts[ride.effort]()}
              </div>
            </div>
          {/if}
          {#if ride.distance}
            <div class="flex">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="2"
                stroke="currentColor"
                class="w-4 h-4 text-gray-500 dark:text-gray-400"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z"
                />
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z"
                />
              </svg>
              <div class="text-xs ml-1 text-gray-900 dark:text-white">
                {units == "imperial"
                  ? `${getMilesFromMeters(ride.distance)} mi`
                  : `${getKilometersFromMeters(ride.distance)} km`}
              </div>
            </div>
          {/if}
          {#if ride.elevation}
            <div class="flex">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-4 h-4 text-gray-500 dark:text-gray-400"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M2.25 18L9 11.25l4.306 4.307a11.95 11.95 0 015.814-5.519l2.74-1.22m0 0l-5.94-2.28m5.94 2.28l-2.28 5.941"
                />
              </svg>

              <div class="text-xs ml-1 text-gray-900 dark:text-white">
                {units == "imperial"
                  ? `${getFeetFromMeters(ride.elevation)} ft`
                  : `${ride.elevation} m`}
              </div>
            </div>
          {/if}
        </div>
      </div>
    </div>
  </div>
</div>
