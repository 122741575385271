<script lang="ts">
  import { goto } from "$app/navigation";
  import LL from "$i18n/i18n-svelte";
  import Input from "$lib/components/ui/components/Input.svelte";
  import authStore from "$lib/stores/authStore";
  import type { User } from "$lib/types";
  import { LocationSearchType } from "$lib/types";
  import { getLocationStorage, locationAutoComplete } from "$lib/utils/locationSearch";
  import Hero from "$src/lib/components/subscribe/Hero.svelte";
  import GettingStarted from "$src/lib/components/home/GettingStarted.svelte";
  import Launch from "$src/lib/components/subscribe/Launch.svelte";
  import LocalGroups from "$src/lib/components/home/LocalGroups.svelte";
  import UpcomingRides from "$src/lib/components/home/UpcomingRides.svelte";
  import posthog from "posthog-js";
  import { MapPin } from "@steeze-ui/heroicons";
  import { Dropdown, DropdownItem } from "flowbite-svelte";
  import { onDestroy, onMount } from "svelte";
  let dropdownOpen = false;
  let locations = [];

  let profile: User, unsubscribe, locationSearch, textSearch;

  onMount(async () => {
    unsubscribe = authStore.subscribe(async ({ profile: p }) => {
      profile = p;

      // Quick hack to pre-populate based on users location
      if (profile?.location) {
        locations = await locationAutoComplete(profile?.location, LocationSearchType.city);
        locationSearch = locations?.[0]?.name;
        dropdownOpen = false;
      }
    });

    if (profile == null) {
      const location = await getLocationStorage();
      if (location) {
        locations = await locationAutoComplete(location.location, LocationSearchType.city);
        locationSearch = locations?.[0]?.name;
        dropdownOpen = false;
      }
    }
  });
  $: unsubscribe && onDestroy(unsubscribe);

  function onBlur() {
    if (locationSearch && locations.length > 0) locationSearch = locations?.[0]?.name;
    setTimeout(() => {
      dropdownOpen = false;
    }, 100);
  }

  function handleLocationSelect(e) {
    e.preventDefault();
    locationSearch = e.target.name;
    dropdownOpen = false;
  }

  function performSearch() {
    posthog.capture("Homepage search", {
      location: locationSearch,
      searchText: textSearch || "",
    });
    goto(`/search?location=${locationSearch}${textSearch ? `&search=${textSearch}` : ""}`);
  }
</script>

<svelte:head>
  <title>Grouprides. Socialized. | Connecting clubs and cyclists together. | Grouprides.io</title>
  <meta
    name="description"
    content="Finding and joining local rides has never been easier. Connect with fellow cyclists, discover new routes, and find the popular rides occurring in your area."
  />
</svelte:head>

<div class="w-full flex flex-col mx-auto">
  <div class="w-full h-96 flex justify-center items-center">
    <div
      class="bg-[url('/images/backgrounds/header.jpg')] w-full h-96 absolute brightness-50 bg-cover bg-no-repeat -z-10"
    />
    <div class="flex flex-col justify-center w-full mx-5 xl:max-w-5xl">
      <div class="text-3xl text-white font-medium drop-shadow-2xl mb-2">
        {$LL.home.searchTitle()}
      </div>
      <form
        class="grid gap-3 md:grid-cols-12 bg-slate-50 p-3 rounded-lg dark:bg-gray-800"
        on:submit|preventDefault={performSearch}
      >
        <div class="relative col-span-12 lg:col-span-4">
          <Input
            icon={MapPin}
            iconTheme="outline"
            name="location"
            onKeyup={async (e) => {
              locations = await locationAutoComplete(e.target.value, LocationSearchType.city);
              locations.length > 0 ? (dropdownOpen = true) : (dropdownOpen = false);
            }}
            {onBlur}
            bind:value={locationSearch}
            placeholder={$LL.home.locationPlaceholder()}
          />
        </div>
        {#if locations?.length !== 0}
          <Dropdown bind:open={dropdownOpen} class="w-64" placement="bottom-start">
            {#each locations as location}
              <DropdownItem
                defaultClass="font-medium py-4 px-6 text-sm hover:bg-gray-100 dark:hover:bg-gray-600"
                name={location.name}
                id={location.placeId}
                on:click={handleLocationSelect}>{location.name}</DropdownItem
              >
            {/each}
          </Dropdown>
        {/if}
        <div class="relative col-span-12 md:col-span-8 lg:col-span-6">
          <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
            <svg
              aria-hidden="true"
              class="w-5 h-5 text-gray-500 dark:text-gray-400"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
              ><path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
              /></svg
            >
          </div>
          <input
            id="search"
            autocomplete="off"
            bind:value={textSearch}
            class="block w-full p-2.5 pl-10 text-sm text-gray-900 border border-gray-300 rounded-md outline-none bg-gray-50 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white focus:ring-emerald-500 focus:border-emerald-500"
            placeholder={$LL.home.searchPlaceholder()}
          />
        </div>
        <button
          type="submit"
          class="w-full col-span-12 md:col-span-4 lg:col-span-2 flex items-center justify-center bg-emerald-400 hover:bg-green-400 text-emerald-900 p-2 rounded-md font-semibold shadow-lg transition ease-in duration-500"
          >{$LL.home.searchButton()}</button
        >
      </form>
    </div>
  </div>
  <div class="flex flex-col justify-center w-full mx-auto xl:max-w-5xl pt-10">
    {#if $authStore.user === null || (profile?.tier !== undefined && profile?.tier === "free")}
      <Launch />
      <div class="flex space-y-4 flex-row justify-center sm:space-y-0 sm:space-x-4">
        <a
          href="/upgrade"
          class="py-2.5 px-8 mr-4 text-sm font-medium text-black focus:outline-none bg-white rounded-md border-2 border-emerald-400 hover:bg-gray-100 focus:z-10 dark:bg-gray-800 dark:text-green-400 dark:border-emerald-500 dark:hover:bg-gray-700"
          ><div class="flex items-center gap-2">
            <img
              class="h-4 block dark:hidden"
              alt="logo"
              src="/images/logos/headerLogoMobile.webp"
            />
            <img
              class="h-4 hidden dark:block"
              alt="logo"
              src="/images/logos/headerLogoWhiteMobile.webp"
            />
            <div>{$LL.createGroup.upgradeModal.viewPlans()}</div>
          </div></a
        >
      </div>
    {/if}
    <Hero />
    <GettingStarted />
    <UpcomingRides />
    <LocalGroups />
  </div>
</div>
